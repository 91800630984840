.checkbox,
.checkbox > * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

.checkbox {
    position: relative;
    display: inline-block;
}

.checkbox:hover .checkbox__label{
    opacity: 1;
}

.checkbox__input {
    width: 20px;
    height: 20px;
    display: block;
    position: relative;
    z-index: 99;
    opacity: 0;

    cursor: pointer;
}

.checkbox__label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;

    background-color: #323232;
    opacity: 0.3;

    transition: all 0.2s ease-out;
}

.checkbox__input:checked + .checkbox__label {
    border: none;
    opacity: 1;
}
