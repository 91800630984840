.long-term {
    height: 100%;
    width: 100%;
    display: flex;
}

.long-term__navbar {
    max-width: 380px;
}

.long-term__navbar-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.long-term__navbar-logo {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #b3b6ba;
}

.long-term__navbar-logo-img {
    width: 140px;
}

.long-term__navbar-subtitle {
    margin-top: 10px;
    width: max-content;
    height: max-content;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 600;
    color: #1a232f;
}

.long-term__navbar-search-input {
    margin-top: 30px;
    height: max-content;
    width: 300px;
}

.long-term__filter-labels-container {
    margin-top: 15px;
    display: flex;
    gap: 5px;
    height: max-content;
}

.long-term__report-item {
    padding: 20px 0 20px 30px;
    display: flex;
    min-height: 105px;
    border-bottom: 1px solid #e4e6e8;
}

.long-term__report-image {
    width: 18px;
    height: 22px;
}

.long-term__report-content {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 10px;
    width: 100%;
}

.long-term__report-info {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 7px;
}

.long-term__navbar-reports-container {
    /* height: 45vh; */
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

.long-term__navbar-reports-container a {
    text-decoration: none;
}

.long-term__report-info-title {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
    color: #1a232f;
}

.long-term__report-info-subtitle {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    color: #666d74;
}

.long-term__report-info-text {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    color: #b3b6ba;
}

.long-term__report-alerts {
    padding: 2px 6px 2px 5px;
    margin-left: auto;
    margin-right: 5px;
    height: 18px;
    min-width: 14px;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    color: white;
    background-color: #dc093c;
    border-radius: 9px;
    z-index: 1310;
}

.long-term__report-alert-popover {
    padding: 10px 14px 10px 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

.MuiPopover-paper {
    border-radius: 8px !important;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-shadow: none !important;
}

.long-term__navbar-button-container {
    margin-top: auto;
    display: flex;
    width: 100%;
    padding: 30px;
    border-top: 1px solid #e4e6e8;
}

.long-term__navbar-request-button {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 700;
    color: white;
    background-color: var(--color-primary);
    border-radius: 8px;
    border: none;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
}

.long-term__map {
    height: 100%;
    width: 100%;
}

.marker {
}

.long-term__search-input__options-icon {
    width: 18px;
    height: 12px;
}

.long-term__search-input {
    display: flex;
    padding: 8px;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
}

/* MAPBOX ZOOM CONTROLS */

.long-term__map .mapboxgl-ctrl-top-right {
    top: 93%;
    right: 2%;
}

.long-term__map .mapboxgl-ctrl.mapboxgl-ctrl-group {
    display: flex;
}

.long-term__map .mapboxgl-ctrl-zoom-in {
    width: 36px;
    height: 36px;
    /* background-image: url(data:image/svg+xml;charset=utf8,<svg%20viewBox%3D%270%200%2020%2020%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27>%0A%20%20<path%20style%3D%27fill%3A%23666D74%3B%27%20d%3D%27M%2010%206%20C%209.446%206%209%206.4459904%209%207%20L%209%209%20L%207%209%20C%206.446%209%206%209.446%206%2010%20C%206%2010.554%206.446%2011%207%2011%20L%209%2011%20L%209%2013%20C%209%2013.55401%209.446%2014%2010%2014%20C%2010.554%2014%2011%2013.55401%2011%2013%20L%2011%2011%20L%2013%2011%20C%2013.554%2011%2014%2010.554%2014%2010%20C%2014%209.446%2013.554%209%2013%209%20L%2011%209%20L%2011%207%20C%2011%206.4459904%2010.554%206%2010%206%20z%27%20%2F>%0A<%2Fsvg>%0A); */
    /* filter: invert(41%) sepia(6%) saturate(664%) hue-rotate(169deg) brightness(98%) contrast(83%); */
    /* background-image: url("/src/assets/icons/ZoomIn.svg"); */
    border-right: 1px solid #e4e6e8;
    border-radius: 0 0 8px 8px;
}

.long-term__map .mapboxgl-ctrl-zoom-out {
    width: 36px;
    height: 36px;
    /* background-image: url(data:image/svg+xml;charset=utf8,<svg%20viewBox%3D%270%200%2020%2020%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27>%0A%20%20<path%20style%3D%27fill%3A%23666D74%3B%27%20d%3D%27m%207%2C9%20c%20-0.554%2C0%20-1%2C0.446%20-1%2C1%200%2C0.554%200.446%2C1%201%2C1%20l%206%2C0%20c%200.554%2C0%201%2C-0.446%201%2C-1%200%2C-0.554%20-0.446%2C-1%20-1%2C-1%20z%27%20%2F>%0A<%2Fsvg>%0A) !important; */
    border-radius: 0 8px 8px 0;
}

.long-term__map .mapboxgl-ctrl-compass {
    display: none;
}
