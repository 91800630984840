.mapboxgl-ctrl-logo {
    @apply hidden;
}

.mapboxgl-ctrl-attrib {
    @apply hidden;
}

.mapboxgl-popup-content {
    @apply p-0 m-0 w-fit;
    @apply pointer-events-auto !important;
    @apply bg-white rounded-lg border-[1px] border-gray-10;
    @apply px-[20px] pt-[14px] pb-[12px];
    @apply flex flex-col items-stretch space-y-[6px];
    @apply mt-16; /* To avoid popovers to crop with the map top edge */
    box-shadow: 0px 4px 8px rgba(29, 41, 52, 0.1);
}

.mapboxgl-popup-tip {
    @apply bg-white border-none z-full;
    @apply w-[14px] h-[14px] transform rotate-45;
    @apply -mb-[8px];
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    @apply mb-[40px];
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-content {
    transform: translateY(8px);
}
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
    @apply mb-[40px];
    @apply -ml-[7px];
}
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content {
    transform: translateY(8px);
    @apply -ml-[12px];
    @apply rounded-bl-[3px];
}
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    @apply mb-[40px];
    @apply -mr-[7px];
}
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-content {
    transform: translateY(8px);
    @apply -mr-[12px];
    @apply rounded-br-[3px];
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-content {
    @apply mt-0;
}
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
    @apply -ml-[7px];
}
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-content {
    @apply mt-0;
    @apply -ml-[12px];
    @apply rounded-tl-[3px];
}
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    @apply -mr-[7px];
}
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-content {
    @apply mt-0;
    @apply -mr-[12px];
    @apply rounded-tr-[3px];
}

.mapboxgl-popup-anchor-left {
    @apply -mt-[18px];
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    @apply m-0;
    @apply -mr-[8px];
    @apply ml-[16px];
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-content {
    @apply mt-0;
}

.mapboxgl-popup-anchor-right {
    @apply -mt-[18px];
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    @apply m-0;
    @apply -ml-[8px];
    @apply mr-[16px];
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-content {
    @apply mt-0;
}
