/* TableHeader Component */
.table-header .table-header__icon {
    /* z-index: 1500; */
    position: absolute;
    bottom: -10px;

    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 12px;

    background: #fff;
    border: 1px solid var(--color-gray-10);

    font-size: 16px;
    color: #505050;

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
}

.table-header.active {
    color: #333;
}

.table-header:hover .table-header__icon {
    opacity: 1;
}

.table-header.active .table-header__icon {
    opacity: 1;
}
