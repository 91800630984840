/* table content */
.table__content.full {
    width: 100% !important;
}

.table__content {
    border-collapse: collapse;
    font-size: 14px !important;

    white-space: nowrap;
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    min-height: 100%;
}

@media screen and (min-width: 1200px) {
    .table__content {
        width: 100%;
    }
}

.table__content * {
    font-family: "Roboto";
}

.table__content .table-item,
.table__content tr {
    display: flex;
    width: 100%;

    height: 52px;
}

.table__content thead {
    background-color: var(--color-primary);

    position: sticky;
    top: 0;

    z-index: 990;
}

.table__content tfoot {
    background-color: var(--color-primary);

    position: sticky;
    bottom: 0;

    z-index: 980;
}
.table__content tfoot tr td {
    background: #fff;
    border-top: 4px solid var(--color-gray-10);
    border-bottom: 1px solid var(--color-gray-10);
    border-right: 1px solid var(--color-gray-10);
    font-weight: bold;
}

.table__content thead tr,
.table__content tfoot tr {
    display: flex;

    height: 52px;

    background-color: var(--color-primary);
    color: white;
}

.table__content tbody tr:nth-child(2n + 1) td {
    background-color: #ffffff;
}

.table__content tbody tr:nth-child(2n) td {
    background-color: var(--color-gray-3);
}

.table__content tbody .table-item .toggler {
    cursor: pointer;
}

.table__content th,
.table__content td {
    position: relative;

    width: 120px;
    height: 100%;
    min-width: 120px;

    display: flex;
    justify-content: center;
    align-items: center;

    white-space: normal;
    word-wrap: break-word;
}

.table__content tr {
    display: flex;
    align-items: center;
}

.table__content th {
    font-weight: 700;
}

.table__content td {
    font-weight: 400;
    text-align: center;
}

.table__content tr td.nested-table {
    height: initial !important;
    width: initial !important;

    border-top: 1px solid #c5c5c5;
    border-bottom: 1px solid #c5c5c5;
    border-left: 1px solid #c5c5c5;
}

.table__content thead th,
.table__content tfoot td {
    color: var(--color-gray-90);

    position: sticky;
    top: 0;
}

.table__content thead th.stick,
.table__content tfoot td.stick {
    position: sticky;
    left: 0;
    z-index: 990;

    min-width: 160px;
    width: 160px;
    /* flex: 1; */
}

.table__content thead th {
    background-color: var(--color-gray-5);
    color: var(--color-gray-90);
    border-bottom: 1px solid var(--color-gray-10);
    border-right: 1px solid var(--color-gray-10);
    font-weight: bold;
}

.table__content tbody th {
    font-weight: normal;

    /* text-align: left; */
    position: relative;
}

.table__content tbody td.stick {
    position: sticky;
    left: 0;
    background: inherit;
    z-index: 1;

    /* min-width: 120px; */
    min-width: 160px;
    width: 160px;
    /* flex: 1; */
}

.table__content tbody tr td {
    border-bottom: 1px solid var(--color-gray-10);
    border-right: 1px solid var(--color-gray-10);
}

.table__content__default-message {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nested-table > .table__content > tbody > tr {
    padding-left: 100px;
}

/* Table content links */

.table__content a:link {
    color: var(--color-primary);
}

/* on item active */

.table-item > td:first-of-type,
.table-item > th:first-of-type,
.table-header:first-of-type {
    /* padding-left: 10px; */
}

.nested-table__container {
    height: initial !important;
}

/* .nested-table__container ~ .table-item {
    opacity: 0.5;
} */

.table-item.active {
    opacity: 1;
}

/* Footer */

.table__content__new-item {
    border-top: 1px solid #c5c5c5;
    border-bottom: 1px solid #c5c5c5;
}

@media screen and (min-width: 670px) {
    .table__content thead th.stick,
    .table__content tfoot td.stick,
    .table__content tbody th.stick,
    .table__content tbody td.stick {
        flex: 1;
    }
}

/* @media screen and (min-width: 1000px) {
    .table__content {
        width: 1200px;
    }

    .table__content thead th.stick,
    .table__content tbody th.stick,
    .table__content tbody td.stick {
        flex: initial;
    }
} */

/* @media screen and (min-width: 1200px) {
    .table__content {
        width: 100%;
    }

    .table__content thead th.stick,
    .table__content tbody th.stick,
    .table__content tbody td.stick {
        flex: 1;
    }
} */
