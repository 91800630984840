.table__loader {
    width: 100%;
    height: 3px;

    display: flex;
    justify-content: center;
}

.table__loader::before {
    content: "";
    display: block;

    width: 50%;
    height: 3px;
    background-color: var(--color-primary);

    animation: table__loader--animation 1s ease-out infinite;
}

/* NOTE: used only on Climate tab, remove and replace with WhiteLabel version after there is Weather style consistency */
.climate .table__loader::before {
    background-color: var(--color-primary);
}

@keyframes table__loader--animation {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}
