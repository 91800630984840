.weather-chart {
    @apply flex flex-col pb-5 px-5 items-start space-y-6;
    @apply lg:flex-row lg:space-y-0 lg:pr-2;
}

.weather-chart__chart-container {
    width: 100%;
    min-width: 300px;
    height: 100%;
    max-height: 700px;
}

.weather-chart__preload-container {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.weather-chart__preload-container div {
    color: var(--color-primary) !important;
}

.weather-chart__specs-container {
    @apply bg-white h-full w-full;
    @apply lg:pt-10 pl-[20px] box-border max-w-[250px];
    @apply flex flex-col space-y-[36px] justify-start;
}

/* GDD Chart */
.weather-chart__gdd-cumulative-toggle {
    position: relative;
    z-index: 930;
    padding: 8px 5px;
    margin-top: -50px;
    top: 45px;
    float: right;

    background-color: white;
    border: 1px solid var(--color-primary);
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgb(56 78 99 / 40%);
}

@media screen and (max-width: 490px) {
    .weather-chart__gdd-cumulative-toggle {
        margin-left: 60px;
        float: left;
    }
}

/* CHART ACTIONS */

.chart-actions__actions-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.chart-action__header-block-option {
    margin: 0 10px;
    width: 100% !important;
    position: relative;
}

.chart-action--media {
    display: initial;
}

/* TODO: Work on the className of the elements of ChartActions */

@media screen and (max-width: 1150px) {
    .chart-actions__actions-container {
        padding: 0 20px;
        justify-content: space-between;
        width: 100%;
    }

    .chart-action__header-block-option {
        width: 130px !important;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1em;
    }
}

@media screen and (max-width: 680px) {
    .chart-actions__actions-container {
        padding: 0;
        gap: 20px;
    }
}

@media screen and (max-width: 550px) {
    .chart-actions__actions-container {
        justify-content: space-evenly;
        gap: 10px;
    }
}

/* DROPDOWN */

.tool__export-dropdown {
    display: block;
}

@media screen and (max-width: 1100px) {
    .tool__export-dropdown {
        display: none;
    }
}
