.powered-by {
    text-align: center;
    color: #9fa0a1;
}

.powered-by img {
    max-width: 120px;
    opacity: 0.5;
}

.powered-by__tag {
    color: #a1a3a5;
    text-decoration: none;
}

.powered-by .powered-by__tag {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}