.section {
    position: relative;
    height: 100%;

    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    overflow: hidden;

    display: flex;
    flex-direction: column;
}

.section__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    row-gap: 10px;
    column-gap: 10px;

    border-bottom: 1px solid #e5e5e5;
}

.section__header__container {
    display: flex;
    position: relative;
    align-items: center;
    column-gap: 10px;
    padding: 8px;
    border-radius: 8px;
}

.section__header__container__info-icon {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 15px;
}

.section__header__title {
    position: relative;

    font-size: 16px;
    font-family: Montserrat;
    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.section__header__controls {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;

    color: #b3b6ba;

    font-family: Roboto;
    @apply lg:pr-8;
}

.section__content {
    flex: 1;
    display: flex;
    flex-direction: column;
}
