.chart-specs__slider-block {
    width: 100%;
}

.chart-specs__specs-block-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    height: 100%;
    width: 100%;
}

.bar-legend-stats {
    margin-top: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bar-legend-stats__block {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.chart-specs__specs-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.chart-specs__slider-block h1,
.chart-specs__specs-block h1 {
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 600;
    color: #1d2934;
}

@media screen and (max-width: 1100px) {
    .chart-specs__specs-block-container {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .chart-specs__specs-block {
        flex-basis: 50%;
        max-width: 160px;
    }

    .chart-specs__specs-block.toggler {
        flex-basis: 25%;
        max-width: unset;
    }
}

@media screen and (max-width: 750px) {
    .chart-specs__specs-block {
        flex-basis: 40%;
        max-width: unset;
    }

    .chart-specs__specs-block.toggler {
        flex-basis: 40%;
        max-width: unset;
    }
}

@media screen and (max-width: 650px) {
    .chart-specs__specs-block {
        flex-basis: 46%;
        max-width: unset;
    }

    .chart-specs__specs-block.toggler {
        flex-basis: 100%;
        max-width: unset;
    }
}

@media screen and (max-width: 500px) {
    .chart-specs__specs-block {
        margin: 0 auto 0 5%;
        flex-basis: 100%;
        max-width: unset;
    }

    .chart-specs__specs-block {
        margin: 0 auto 0 5%;
        flex-basis: 100%;
    }
}

.chart-specs__range-date__input-container {
    display: flex;
    align-items: center;

    margin-top: 5px;
    padding: 8px;
    border: 1px solid #e6e7e8;
    border-radius: 8px;
}

.chart-specs__range-date__input-container__icon {
    width: 20px;
    height: 100%;
    margin-right: 10px;
}

.chart-specs__range-date__input-container__icon svg path {
    fill: #666d74;
}

.chart-specs__range-date-selector__wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    margin-left: -5px;
}

div.chart-specs__range-date-selector__wrapper.number-selector,
.number-selector div.number-selector__input-error {
    margin-top: 0px;
}

.number-selector__input-error {
    color: red;
    font-family: "Montserrat";
    font-size: 12px;
}

.chart-specs__range-date-selector__wrapper > div {
    margin-top: 20px;
}
.chart-specs__range-date-selector__label {
    color: #666d74;
    margin-bottom: 5px;
}

.chart-specs__range-date-selector__wrapper .react-datepicker__input-container input {
    cursor: pointer;
    outline: none;
    border: none;
}
