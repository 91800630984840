.map-container {
  width: 100%;
  height: 100%;
}

/*********** MAPBOX MAP OVERRIDES ***************/

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-attrib {
  display: none !important;
}

.mapboxgl-popup-content {
  pointer-events: none;
  z-index: 9;
}

.mapboxgl-popup-close-button {
  display: none;
}
.mapboxgl-marker.mapboxgl-marker-anchor-center {
  cursor: pointer;
}
