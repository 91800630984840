.sign-in-side__checkbox-container {
    position: relative;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: max-content;
}

.sign-in-side__checkbox {
    position: relative;
    display: inline-block;
    margin-left: 1px;
    margin-right: 5px;
    margin-top: 5px;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid gray;
}

.sign-in-side__checkbox-input {
    width: 12px;
    height: 12px;
    display: block;
    position: relative;
    z-index: 99;
    opacity: 0;
}

.sign-in-side__checkbox-input:hover {
    cursor: pointer;
}

.sign-in-side__checkbox-label {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.sign-in-side__checkbox-input:checked + .sign-in-side__checkbox-label {
    border: none;
    background-color: #666d74;
}

.sign-in-side__checkbox-text {
    position: absolute;
    padding-left: 20px;
    padding-top: 4px;
    width: 200px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #666d74;
}
