/* Table styles */

.table,
.table * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.table {
    display: flex;
    flex-direction: column-reverse;

    position: relative;
}

.table__container {
    width: 100%;
    height: 100%;
    /* box-shadow: var(--shadow-generic); */
    /* border: 1px solid #cacdd0aa; */
    /* background: linear-gradient(131.47deg, #f8fbff 7.84%, #eff2f6 93.19%); */
}

.table__main-header {
    width: 100%;
    padding: 10px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table__main-header__title {
    display: none;
    align-items: center;
    flex-grow: 1;
}

.table__main-header__title__controls-left {
    padding-left: 50px;

    display: flex;
    padding-right: 20px;
}
.table__main-header__title__controls-right {
    padding-left: 20px;
    border-left: 1px solid #ddd;

    /* position: absolute; */

    /* left: 90px; */

    display: flex;
    justify-content: center;
    align-items: center;
}

.table__main-header__title__button {
    border-radius: 5px;
    padding: 6px 15px;

    border: none;
    border: 1px solid var(--color-primary);
    outline: none;

    color: var(--color-primary);
    font-weight: bold;
    font-size: 14px;
    font-family: Montserrat;

    cursor: pointer;

    transition: all 0.2s ease-out;
}
.table__main-header__title__button > div {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.table__main-header__title__button:hover {
    background-color: #ddd;
}

.table__main-header__title__checkbox-container {
    width: 60px;
    padding-right: 25px;

    position: absolute;
    left: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    /* padding-left: 15px; */
}

.table__main-header__title__controls-right__label-button {
    padding-right: 22px;
}

.table__main-header__title__select {
    display: block;
    border: none;
    background: #dee0e3;
    padding: 3px;
    padding-left: 7px;
    padding-right: 7px;

    font-size: 10px;
    font-weight: 500;

    border-bottom: 2px solid #666;
    border-top-left-radius: 2px;
    border-top-right-radius: 4px;
    cursor: pointer;
}

.table__main-header__title__text {
    padding-left: 28px;

    font-size: 18px;
    font-weight: 700;
    font-family: "Montserrat";
}

.table__main-header__search-bar {
    height: 40px;
    width: 100%;

    border-bottom: 1px solid #000;

    display: flex;
    justify-content: center;
    align-items: center;
}

.table__main-header__search-bar__icon {
    width: 20px;
    height: 20px;
}

.table__main-header__search-bar__input {
    padding-left: 8px;
    padding-right: 8px;
    height: inherit;

    width: 90%;

    border: none;
    outline: none;
    background: none;

    font-size: 14px;

    flex: 1;
}

.table__main-header__search-bar__button {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    margin: 5px;
    padding: 7px;

    border: none;
    background: none;
    outline: none;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color-climate-green);

    cursor: pointer;

    transition: all 0.2s ease-out;
}

.table__main-header__search-bar__button:hover {
    background-color: #ddd;
}

/* Table Scroll */
.table__scroll-container {
    position: relative;

    width: 100%;
    height: 100%;
    background-color: white;

    overflow-x: scroll;
    overflow-y: scroll;

    /* IE and Edge */
    /* -ms-overflow-style: none;  */
    /* Firefox */
    /* scrollbar-width: none;  */
}

.table.table-climate .table__scroll-container {
    overflow-y: scroll;
}

/* Fixes table taking more space than neede in Historical Validation */
.historical .table__scroll-container {
    max-height: 160px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .table__scroll-container::-webkit-scrollbar {
    display: none;
} */

.table__shadow {
    position: absolute;
    top: 0;
    left: 0;

    z-index: 9999;

    width: 100px;
    height: 100%;

    /* background-color: black; */
    box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.25);

    pointer-events: none;
}

.table-container__draggable-headers {
    display: none;
}

/* table pagination */
.table__pagination {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

    align-self: flex-end;
}

/* table alert */
.alert-dot {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background: #ffbd3d;
}

/* table shape file */
.shape-file {
    width: 16px;
    height: 16px;
    border-radius: 8px;

    background: #22c11f;

    font-size: 14px;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
}

/* table weather file */
.weather-file {
    font-size: 18px;
    color: #ffbd3d;
}

.table__loader {
    width: 100%;
    height: 2px;

    display: flex;
    justify-content: center;
}

.table__loader::before {
    content: "";
    display: block;

    width: 50%;
    height: 2px;
    background-color: var(--color-climate-green);

    animation: table__loader--animation 1s ease-out infinite;
}

.table__main-header__map-toggler {
    display: none;
    /* padding-right: 10px; */
    padding-left: 20px;
}

@keyframes table__loader--animation {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

@media screen and (min-width: 1000px) {
    .table {
        flex-direction: column;
    }

    .table__container {
        border: none;
    }

    .table__main-header__title {
        display: flex;
    }

    .table__main-header__search-bar {
        width: 250px;
    }

    .table__main-header__map-toggler {
        display: block;
    }

    .table-container__draggable-headers {
        display: block;
    }

    .table__scroll-container {
        width: 100%;
        overflow-x: scroll;
        /* overflow-y: scroll; */
    }

    .table.table-climate .table__scroll-container {
        overflow-y: scroll;
    }
}

@media screen and (min-width: 1400px) {
    .table {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .table__container {
        border-radius: 20px;
        border: 1px solid #cacdd0aa;
    }

    .table.table-climate .table__container {
        border-radius: initial;
    }
}
