.small-controls * {
    font-family: Roboto;
    font-size: 14px;
}

.small-controls {
    width: 200px;
    padding: 8px 15px;
    border-radius: 10px;

    z-index: 100000;
    position: absolute;
    top: 80%;
    left: 80%;

    background-color: #fff;
    border: 1px solid var(--color-gray-10);
    /* box-shadow: 0 1px 2px 0 #00000044; */
}

.small-controls.down {
    left: 80%;
}

.small-controls.up {
    top: initial;
    bottom: 80%;
}

.small-controls.left {
    left: initial;
    top: 50%;
    right: 80%;
}
