.regional-map__toggle__container {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
}

.regional-map__toggle__container.active {
    background-color: var(--color-gray-60);
}

.regional-map__toggle__icon {
    width: 20px;
    height: 20px;
    margin: 0px 6px;
}

.regional-map__toggle__container.active .regional-map__toggle__icon path {
    fill: white;
}
