.menu-item {
    position: relative;

    height: 36px;
    padding: 7px 8px;

    background: none;
    border: none;
    outline: none;

    cursor: pointer;

    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.menu__children {
    z-index: 999;
}

.menu-item__children {
    position: absolute;
    top: 0;
    left: 100%;
    display: none;
    max-height: 400px;
    width: max-content;
    overflow-y: scroll;
}

.menu.left .menu-item__children {
    left: initial;
    right: 100%;
}

.menu-item:hover > .menu-item__children {
    display: block;
}

.menu-item:hover {
    background-color: #f9f9f9;
}

.menu-item__value {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-item__value_label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
}

.menu-item__value__arrow {
    display: none;
}
.menu-item:hover > .menu-item__value > .menu-item__value__arrow {
    display: block;
}
