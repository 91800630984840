.menu {
    position: relative;
    display: flex;
    background: none;
    border: none;
    outline: none;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.menu__children {
    position: absolute;
    min-width: 140px;
    max-height: 500px;
    overflow-y: auto;
    background-color: #fff;
    top: 100%;
    right: 0;

    border-radius: 8px;

    border: 1px solid #ddd;
}

.menu > .select {
    cursor: pointer;
}
