input:checked + .alert__content__slider-round {
    background: linear-gradient(180deg, #22c11f 0%, #03b800 100%);
}

input:checked + .alert__content__slider-round:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

.alert__content__conditions {
    padding: 20px;
    padding-left: 40px;
}

[class^="alert__content__conditions__options-"] {
    margin-bottom: 5px;

    display: flex;

    font-family: "Roboto";
    font-size: 13px;
    color: #505050;
}

[class^="alert__content__conditions__options-"] strong {
    color: #1d2934;
}

.alert__content__conditions__options-s-margin,
.alert__content__conditions__options-m-margin {
    padding-top: 10px;
    display: flex;
    flex-flow: row wrap;
}

.alert__content__conditions__options-m-margin {
    margin-left: 20px;
}

.alert__content__conditions__options-l-margin {
    margin-top: 25px;
    margin-left: 40px;
}

.alert__content__conditions__container {
    max-width: 75px;
}

.alert__content__conditions__input {
    margin-right: 8px;
    margin-left: 8px;

    width: 50px;

    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: #2c2c2c;

    background-color: white;
    border: 1px solid rgba(130, 130, 130, 0.5);
    border-radius: 5px;
}

.alert__content__conditions__input::placeholder {
    color: lightgray;
}

.alert__content__conditions__input:disabled {
    background-color: #e9ebef;
}

.alert__content__conditions__input .alert__content__conditions__input:disabled {
    color: #2c2c2c;
    background-color: #e2e2e2;
}

.alert__content__conditions__input-error {
    color: red;
    margin-left: -100%;
    min-width: 400%;
    font-family: "Montserrat";
    font-size: 12px;
}

.alert__content__edit-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    margin-right: 30%;
}

.alert__content__edit-icon {
    height: 22px;
    width: 22px;
}

.alert__content__edit-icon:hover {
    cursor: pointer;
}

.alert__content__trash-icon {
    height: 22px;
    width: 22px;
}

.alert__content__trash-icon:hover {
    cursor: pointer;
}

.alert__content__cancel-button {
    margin-top: 30px;
    margin-bottom: 7px;

    width: 115px;
    height: 22px;
    font-size: 12px;
    font-weight: 600;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.alert__content__cancel-button:hover {
    cursor: pointer;
}

.alert__content__save-button {
    width: 90px;
    height: 22px;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    color: white;
    background-color: var(--color-primary);
    border: none;
    border-radius: 20px 20px 20px 20px / 20px 20px 20px 20px;
}

.alert__content__save-button:hover {
    cursor: pointer;
}

.alert__header__loading-bar {
    position: absolute;
    top: 1px;
    width: 96%;
    left: 2%;
}

@media screen and (max-width: 600px) {
    .alert__container {
        border-radius: 0;
    }

    .toggle-container {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }

    .alert__content__edit-button-container {
        margin-right: 10px;
    }
}
