.horizontal_separator {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #666D74;
  }
  
  .horizontal_separator::before,
  .horizontal_separator::after {
    content: '';
    flex: 1;
    border-bottom: 2px solid #E6E7E8;
  }
  
  .horizontal_separator:not(:empty)::before {
    margin-right: 18px;
  }
  
  .horizontal_separator:not(:empty)::after {
    margin-left: 18px;
  }