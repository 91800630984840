.chill-hours-initial-view {
    max-width: 240px;
    margin-top:-60px;
}


.chill-hours-initial-view__header {
    color: rgba(26, 35, 47, 1) !important;
    font-weight: 500;
    margin: 20px 0px;
    line-height: 22px;
    font-size:18px;
}

.chill-hours-initial-view__body {
    color: rgba(26, 35, 47, 1) !important;
    margin: 20px 0px;
    line-height: 22px;
    font-size: 14px;
}