/* these are for keeping the border of the sticky header row after scrolling */
/* (using box-shadow to achieve the trick) */

.csv_table__content tbody:nth-of-type(1) tr:nth-of-type(1) td {
    border-top: none !important;
}
  
.csv_table__content thead th {
    border-top: none !important;
    border-bottom: none !important;
    box-shadow: inset 0 1px 0 rgb(230 231 232), inset 0 -1px 0 rgb(230 231 232);
    padding: 1px 0;
} 
  
/* small fix for weird FF box-shadow behavior, described in https://stackoverflow.com/questions/7517127/ */
  
.csv_table__content thead th {
    background-clip: padding-box
}

/* for the nice intercalating table row bg colors */
  
.csv_table__content tbody tr:nth-child(2n + 1) {
    background-color: #ffffff;
}

.csv_table__content tbody tr:nth-child(2n) {
    background-color: var(--color-gray-3);
}

/* table content links */

.csv_table__content a:link {
    color: var(--color-primary);
}